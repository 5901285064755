import React, { Component } from "react"
import styled from "styled-components"
import {GatsbyImage} from "gatsby-plugin-image"
import CopyBox from "components/shared/CopyBox"

import LazyLoad from "react-lazyload"

const HoldWrap = styled.div`
    width: 100vw;
    position: absolute;
    z-index: 8;
    left: 0;
    transition: all .8s ease-in-out;
    visibility: hidden;
    background-color: black;
    opacity: 0;

    height: 100vh; 
    top: 0;

    &.active {
        visibility: visible;
        opacity: 1;
    }
`

const BgWrapper = styled.div`
     width: 100vw;
        height: 100vh;
        position: absolute;
        left: 0;
        z-index: 2;
        top: 0;
        
        &--step2 {
            opacity: 0;
        }
        
        .image {
            width: 100vw;
            height: 100vh;
        }
`

const BgImg = styled(GatsbyImage)`
    pointer-events: none;
`

class HoldScene extends Component {
    render() {
        return (
            <HoldWrap>
                <BgWrapper>
                <LazyLoad height={"100%"} once>
                <BgImg image={this.props.bgImageSm}
                    alt=""
                    title=""
                    className="image d-block d-sm-none"
                />
                </LazyLoad>
                <LazyLoad height={"100%"} once>
                <BgImg image={this.props.bgImageMd}
                    alt=""
                    title=""
                    className="image d-none d-sm-block d-lg-none"
                />
                </LazyLoad>
                <LazyLoad height={"100%"} once>
                <BgImg image={this.props.bgImageLg}
                    alt=""
                    title=""
                    className="image d-none d-lg-block d-xl-none"
                />
                </LazyLoad>
                <LazyLoad height={"100%"} once>
                <BgImg image={this.props.bgImageXl}
                    alt=""
                    title=""
                    className="image d-none d-xl-block"
                />
                </LazyLoad>
                </BgWrapper>
                <CopyBox
                    copyHorizontalPosition={this.props.copyHorizontalPosition}
                    copyHorizontalPositionMd={this.props.copyHorizontalPositionMd}
                    copyVerticalPosition={this.props.copyVerticalPosition}
                    copyVerticalPositionSm={this.props.copyVerticalPositionSm}
                    copyVerticalPositionMd={this.props.copyVerticalPositionMd}
                    copyMdWidth={this.props.copyMdWidth}
                    copyOnMobile={this.props.copyOnMobile}
                    quote={this.props.quote}
                    color="orange">
                    {this.props.text !== '' &&
                    <div dangerouslySetInnerHTML={{ __html: this.props.text }} />
                    }
                </CopyBox>
            </HoldWrap>
        )
    }
}

HoldScene.defaultProps = {
    text: '',
    quote: false,
    copyHorizontalPosition: "toLeft",
    copyVerticalPosition: "toLeft",
    copyVerticalPositionSm: "toMiddle"
}

export default HoldScene