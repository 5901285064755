import React, {Component} from "react"
import {media} from "utils/Media"
import styled from "styled-components"
import LayoutInteractive from "components/Layout/LayoutInteractive"
import ParallaxImage from "components/Interactive/ParallaxImage"
import Menu from "components/Interactive/Menu"
import TitleBar from 'components/shared/TitleBar'
import Simba from "components/Interactive/Characters/Simba"
import Mufasa from "components/Interactive/Characters/Mufasa"
import Nala from "components/Interactive/Characters/Nala"
import Rafiki from "components/Interactive/Characters/Rafiki"
import Zazu from "components/Interactive/Characters/Zazu"
import Scar from "components/Interactive/Characters/Scar"
import TimonAndPumbaa from "components/Interactive/Characters/TimonAndPumbaa"

import DownloadButton from "components/shared/DownloadButton"
import IconDownload from 'images/icons/icon-download-white.svg'

import {graphql, StaticQuery} from "gatsby";
import {isBrowser, isChrome} from "react-device-detect";

const MobileTitleBar = styled(TitleBar)`
    display: block;
    cursor: pointer;
    
    &.hidden {
        display: none;
    }
    
    @media ${media.sm} {
        display: none;
        
        &.hidden {
            display: block;
        }
    }
`

const scrollToElement = require('scroll-to-element');

const Query = () => (
    <StaticQuery
        query={graphql`
            query {
                image: file(relativePath: { eq: "Interactive/Characters/lke-im-char-header-1920.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                imageLg: file(relativePath: { eq: "Interactive/Characters/lke-im-char-header-1440.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1440, quality: 100)
                    }
                }
               
                imageSm: file(relativePath: { eq: "Interactive/Characters/lke-im-char-header-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                imageXs: file(relativePath: { eq: "Interactive/Characters/lke-im-char-header-576.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
            }
		`}
        render={data => (
            <InteractivePage data={data}/>
        )}
    />
)

class InteractivePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            scene: '',
            displayContent: false,
            imagesAreLoaded: [],
            activeSceneName: "Simba",
            activePageName: "2/3 Meet the Characters",
            sceneList: [
                {
                    name: "Simba",
                    slug: "simba",
                    color: "yellow",
                    active: true
                },
                {
                    name: "Mufasa",
                    slug: "mufasa",
                    color: "orange",
                    active: false
                },
                {
                    name: "Nala",
                    slug: "nala",
                    color: "purple",
                    active: false
                },
                {
                    name: "Rafiki",
                    slug: "rafiki",
                    color: "red",
                    active: false
                },
                {
                    name: "Zazu",
                    slug: "zazu",
                    color: "blue",
                    active: false
                },
                {
                    name: "Scar",
                    slug: "scar",
                    color: "darkGrey",
                    active: false
                },
                {
                    name: "Timon & Pumbaa",
                    slug: "timonAndPumbaa",
                    color: "green",
                    active: false
                }
            ],
            pageList: [
                {
                    name: "1/3 The Story",
                    slug: "/interactive/the-story",
                    active: false
                },
                {
                    name: "2/3 Meet the Characters",
                    slug: "/interactive/meet-the-characters",
                    active: true
                },
                {
                    name: "3/3 About The Production",
                    slug: "/interactive/about-the-production",
                    active: false
                },
                // {
                //     name: "4/4 Fascinating Facts",
                //     slug: "/interactive/fascinating-facts",
                //     active: false,
                // }
            ]
        }
    }

    toggleScene = (scene) => {
        this.setState({scene}, () => {
            scrollToElement(`#${scene}Scene`, {
                duration: 300
            })
        })

    }

    imagesLoaded = (isLoaded) => {
        let imagesAreLoaded = this.state.imagesAreLoaded
        let displayContent = this.state.displayContent

        imagesAreLoaded.push(isLoaded)

        if (isBrowser) {
            if (imagesAreLoaded.length === 1) {
                displayContent = true
            }
        } else {
            displayContent = true
        }

        this.setState({imagesAreLoaded, displayContent})
    }

    render() {
        return (
            <LayoutInteractive slug="interactive/meet-the-characters" title="Meet the Characters">
                <Menu
                    sceneList={this.state.sceneList}
                    activeSceneName={this.state.activeSceneName}
                    pageList={this.state.pageList}
                />
                <ParallaxImage imagesAreLoaded={this.imagesLoaded} data={this.props.data}
                               title="Meet the<br/ >characters"/>

                {this.state.displayContent &&
                <React.Fragment>
                    <MobileTitleBar className={this.state.scene === 'simba' ? 'hidden' : ''} color="yellow" lessPadding
                                    onClick={() => this.toggleScene('simba')}>
                        <h2>Simba</h2>
                    </MobileTitleBar>

                    <Simba active={this.state.scene === 'simba'}/>

                    <MobileTitleBar className={this.state.scene === 'mufasa' ? 'hidden' : ''} color="orange" lessPadding
                                    onClick={() => this.toggleScene('mufasa')}>
                        <h2>Mufasa</h2>
                    </MobileTitleBar>

                    <Mufasa active={this.state.scene === 'mufasa'}/>

                    <MobileTitleBar className={this.state.scene === 'nala' ? 'hidden' : ''} color="purple" lessPadding
                                    onClick={() => this.toggleScene('nala')}>
                        <h2>Nala</h2>
                    </MobileTitleBar>

                    <Nala active={this.state.scene === 'nala'}/>

                    <MobileTitleBar className={this.state.scene === 'rafiki' ? 'hidden' : ''} color="red" textWhite
                                    lessPadding onClick={() => this.toggleScene('rafiki')}>
                        <h2>Rafiki</h2>
                    </MobileTitleBar>

                    <Rafiki active={this.state.scene === 'rafiki'}/>

                    <MobileTitleBar className={this.state.scene === 'zazu' ? 'hidden' : ''} color="blue" textWhite
                                    lessPadding onClick={() => this.toggleScene('zazu')}>
                        <h2>Zazu</h2>
                    </MobileTitleBar>

                    <Zazu active={this.state.scene === 'zazu'}/>

                    <MobileTitleBar className={this.state.scene === 'scar' ? 'hidden' : ''} color="darkGrey" textWhite
                                    lessPadding onClick={() => this.toggleScene('scar')}>
                        <h2>Scar</h2>
                    </MobileTitleBar>

                    <Scar active={this.state.scene === 'scar'}/>

                    <MobileTitleBar className={this.state.scene === 'timonAndPumbaa' ? 'hidden' : ''} color="green"
                                    onClick={() => this.toggleScene('timonAndPumbaa')}>
                        <h2>Timon & Pumbaa</h2>
                    </MobileTitleBar>

                    <TimonAndPumbaa active={this.state.scene === 'timonAndPumbaa'}/>
                </React.Fragment>
                }
                <DownloadButton className="mx-auto mb-4 mt-5" download target="_blank" href="/pdfs/Disneys_The_Lion_King_Meet_The_Characters.pdf">Download&nbsp;PDF <img src={IconDownload} alt="" /></DownloadButton>
            </LayoutInteractive>
        )
    }
}

export default Query