import React from "react"
import styled, {css} from "styled-components"
import {media} from "utils/Media"
import quoteRed from 'images/Interactive/quote-red.svg';
import quoteOrange from 'images/Interactive/quote-orange.svg';
import quoteGreen from 'images/Interactive/quote-green.svg';

const CopyBox = styled.div`
    position: absolute;
    z-index: 8;
    width: calc(100% - 20%);
    left: 20px;
    top: 70px;
    pointer-events: none;
    
    @media ${media.md} {
        left: 5%;
        max-width: calc(100% - 20%);
        transform: translateY(-50%);
        top: 50%;
        width: 320px;
    }
    
    @media ${media.lg} {
      width: 280px;
    }
    
    @media ${media.xl} {
        left: 10%;  
        width: 400px;
    }

    p {
        color: ${props => props.theme.colors.white};
        font-family: ${props => props.theme.font.family.semiBold};
        font-size: 1.25rem;
        line-height: 120%;
        letter-spacing: 0.03em;
        
        @media ${media.lg} {
          font-size: 1.25rem;
        }
        
        @media ${media.xl} {
            font-size: 1.75rem;
        }
    }
    
    h3 {
        color: ${props => props.theme.colors.white};
        font-family: ${props => props.theme.font.family.semiBold};
        font-size: 2rem;
        line-height: 120%;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        
        @media ${media.xl} {
            font-size: 2.5rem;
        }
    }
    
    ${props => props.copyOnMobile === 'hidden' && css`
      @media (max-width: 575px) {
        display: none!important;
      }
    `}

    ${props => props.step1 && css`
        background-image: linear-gradient(#fff, #fff);
        -webkit-background-clip: text;
    `}

    ${props => props.step2 && css`
        opacity: 0;
    `}
    
    ${props => props.ontop && css`
        z-index: 9;
    `}

    ${props => props.copyVerticalPositionXs === 'toBottom' && css`
      @media (max-width: 575px) {
        top: auto;
        bottom: 130px;
      }
    `}
    
    ${props => props.copyVerticalPositionXs === 'toTop' && css`
      @media (max-width: 575px) {
        top: 70px;
        bottom: auto;
      }
    `}
     
    ${props => props.copyVerticalPositionSm === 'toTop' && css`
      @media (min-width: 768px) and (max-width: 991px) {
        top: 130px;
        bottom: auto;
        transform: none;
      }
    `}
    
    ${props => props.copyVerticalPosition === 'toBottom' && css`
        bottom: 80px;
        top: initial;
    
        @media ${media.sm} {
            bottom: 80px;
            top: initial;
        }
        
        @media ${media.md} {
            bottom: -40px;
            top: initial;
        }
    
        @media ${media.xl} {
            bottom: 60px;
            top: initial;
        }
    `}
    
    ${props => props.copyVerticalPosition === 'toTop' && css`
        @media ${media.lg} {
            top: 130px !important;
            transform: none;
        }
    `}
     
    ${props => props.copyVerticalPositionMd === 'toBottom' && css`
        @media (max-width: 991px) {
            width: 90%;
            max-width: 450px;
            bottom: 130px;
            top: initial;
            transform: none;
        }
    `}
    
    ${props => props.copyVerticalPositionMd === 'toBottom' && props.noScroller && css`
        @media (max-width: 991px) {
            bottom: 50px;
        }
    `}
       
    ${props => props.copyVerticalPositionMd === 'toTop' && css`
        @media (max-width: 991px) {
            width: 90%;
            max-width: 450px;
            top: 50px;
            //left: 40px !important;
            //right: initial !important;
            bottom: initial;
            transform: none;
        }
    `}
    
   ${props => props.copyMdWidth === 'fullWidth' && css`
        @media (max-width: 991px) {
            max-width: initial !important;
        }
    `}
     
   ${props => props.copyMdWidth === 'narrow' && css`
        @media (max-width: 991px) {
            width: 320px !important;
        }
    `}
    
    ${props => props.copyHorizontalPosition === 'toRight' && css`
        left: 20px;
        
        @media ${media.md} {
            right: 5%;
            left: auto;
        }
        
        @media ${media.lg} {
            right: 2rem;
            left: auto;
        }
        
        @media ${media.xl} {
            left: auto;
            right: 10%;
        }
    `}
    
    ${props => props.copyHorizontalPositionMd === 'toLeft' && css`
        @media (min-width: 576px) and (max-width: 991px) {
            left: 20px;
            right: initial;
        }
    `}
    
    ${props => props.copyHorizontalPositionMd === 'toRight' && css`
        @media (min-width: 576px) and (max-width: 991px) {
            right: 20px !important;
            left: initial !important;
        }
    `}
    
    ${props => props.quote && css`
      @media (max-width: 575px) {
        left: 40px !important;
        top: 100px !important;
      }
    
        @media ${media.sm} {
            top: 130px;
        }
        
        @media ${media.md} {
            top: 50%;
        }
        
        @media ${media.xl} {
            top: 50%;
        }
        
        ${props => props.copyVerticalPositionMd === 'toTop' && css`
        @media (max-width: 991px) {
            width: 90%;
            max-width: 450px;
            top: 50px;
            //left: 40px !important;
            //right: initial !important;
            bottom: initial;
            transform: none;
        }
    `}
        p {
            color: ${props => props.theme.colors.yellow};
            font-family: ${props => props.theme.font.family.semiBold};
            font-size: 1.25rem;
            line-height: 120%;
            letter-spacing: 0.03em;
            
            
            @media ${media.md} {
                font-size: 1.75rem;
            }
            
            @media ${media.xxl} {
                font-size: 2.5rem;
            }
        }

        div {
            position: relative;
            z-index: 2;
        }
        
        &:before {
            content: "";
            position: absolute;
            top: -40px;
            left: -20px;
            width: 70px;
            height: 70px;
            
            ${props => props.color === 'red' && css`
                background: url(${quoteRed}) no-repeat center center;
                background-size: contain;
            `}
            
            ${props => props.color === 'green' && css`
                background: url(${quoteGreen}) no-repeat center center;
                background-size: contain;
            `}
            
            ${props => props.color === 'orange' && css`
                background: url(${quoteOrange}) no-repeat center center;
                background-size: contain;
            `}
            
            @media ${media.xxl} {
                top: -70px;
                left: -60px;
                width: 101px;
                height: 163px; 
            }
        }
        
        &:after {
            content: "";
            transform: rotate(180deg);
            position: absolute;
            bottom: -30px;
            right: 0;
            width: 70px;
            height: 70px;
            
            ${props => props.color === 'red' && css`
                background: url(${quoteRed}) no-repeat center center;
                background-size: contain;
            `}
            
            ${props => props.color === 'green' && css`
                background: url(${quoteGreen}) no-repeat center center;
                background-size: contain;
            `}
            
            ${props => props.color === 'orange' && css`
                background: url(${quoteOrange}) no-repeat center center;
                background-size: contain;
            `}
            
            @media ${media.xxl} {
                bottom: -60px;
                width: 101px;
                height: 163px;
            }
        }
    `}
`

CopyBox.defaultProps = {
    quote: false,
    copyHorizontalPosition: "toLeft",
    copyVerticalPosition: "toMiddle",
    copyVerticalPositionXs: "toTop"
}

export default CopyBox