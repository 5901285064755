import React, {Component} from 'react';
import styled from 'styled-components';
import KalturaModal from "components/shared/KalturaModal"
import {media} from "utils/Media"
import btnBg from 'images/Interactive/btn-video-lozenge.svg'
import playBtn from 'images/Interactive/btn-video-play.svg'
import playBtnHover from 'images/Interactive/btn-video-play-black.svg'
import VisibilitySensor from "react-visibility-sensor";

const PlayButtonWrapper = styled.div`
    position: absolute;
    bottom: 56px;
    right: 0;
    z-index: 20;
    width: 100%;
    transition: bottom 0.2s ease-in;
        
    &.visible {
        right: 0;
    }
    
    @media ${media.sm} {
        width: initial;
        bottom: 100px;
        right: -319px;
        transition: right 1s ease-in;
        
        &.visible {
            right: 0;
        }
    }
`
const PlayButtonContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: ${props => props.theme.colors.white};
    padding: 10px 0;
    
    @media ${media.sm} {
        background: url(${btnBg}) no-repeat center center;
        background-size: contain;
        width: 320px;
        height: 80px;
        padding: 0;
    }
    
    .playBtn {
        width: 60px;
        height: 60px;
        background: url(${playBtn}) no-repeat center center;
        background-size: contain;
        border: none;
        //transition: all 0.2s ease-in;
        
        &:hover {
            background: url(${playBtnHover}) no-repeat center center;
            background-size: contain;
            width: 60px;
            height: 60px;
            
            @media ${media.sm} {
                width: 70px;
                height: 70px;
            }
        }
        
        @media ${media.sm} {
            width: 70px;
            height: 70px;
        }
    }
    
    .copy {
        width: calc(100% - 82px);
        padding-left: 10px;
        padding-right: 10px;
        
        span {
            font-family: ${props => props.theme.font.family.base};
            font-size: 1.25rem;
            line-height: 1.5rem;
            letter-spacing: 0.03em;
        }
    }
`

class PlayButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false,
            isPlayBtnVisible: false
        }
    }

    onChange = (isVisible) => {
        this.setState({
            isPlayBtnVisible: isVisible
        })
    }

    toggleModal = (e, src) => {
        e.preventDefault();

        this.setState({
            modalOpen: !this.state.modalOpen
        });
    }

    render() {
        return (
            <VisibilitySensor onChange={this.onChange} partialVisibility={true}>
                <PlayButtonWrapper className={`${this.state.isPlayBtnVisible && 'visible'}`}>
                    <PlayButtonContent>
                        <button className="playBtn" onClick={(e) => this.toggleModal(e, this.props.video)}/>
                        <div className="copy">
                            <span>{this.props.copy}</span>
                        </div>
                        <KalturaModal toggleModal={this.toggleModal} src={this.props.src}
                                      modalOpen={this.state.modalOpen}/>
                    </PlayButtonContent>
                </PlayButtonWrapper>
            </VisibilitySensor>
        );
    }
}

export default PlayButton;