import styled, { css } from "styled-components"
import { media } from "utils/Media"

const Absolute = styled.div`
     position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 56px;
  
  @media ${media.sm} {
    top: 48px;
  }
`

export default Absolute