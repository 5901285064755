// @flow
import React, {Component} from 'react';
import styled, {css} from 'styled-components';
import {media} from "utils/Media"
import {Controller, Scene} from 'react-scrollmagic';
import {Tween, Timeline} from 'react-gsap';
import {graphql, StaticQuery} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import PlayButton from 'components/shared/PlayButton'
import TitleBar from 'components/shared/TitleBar'
import CopyBox from "components/shared/CopyBox"
import ButtonScroll from "components/shared/ScrollButton"
import Absolute from "components/shared/Absolute"

const Relative = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const Wrapper = styled.div`
  overflow: hidden;
  
  .scrollmagic-pin-spacer {
    display: none !important;

    ${props => props.active && css`
        display: block !important;
    `}

    @media ${media.sm} {
        display: block !important;
    }
  }
  
  .sticky, .sticky2 {
    height: 0;
    overflow: hidden;
    width: 100% !important;
    
    ${props => props.active && css`
        height: 100vh;
    `}
    
    @media ${media.sm} {
        height: 100vh;
    }
  }
  
  .fs {
        height: 100vh;
    }
    
     .slideInUp {
        .no-touch & {
            visibility: hidden;
            opacity: 0;
            transition: all 0.7s ease-out;
            transform: translate3d(0, 50px, 0);
        }
        .no-touch .is-active & {
            visibility: visible;
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
    
    .pinContainer {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
    }
    
    .animation__background {
        background-image: linear-gradient(#6480BF 15.62%, #F4A65D 57.29%, #FBD647 87.5%);
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 1;
        opacity: 1;
    }
    
    .animation__simbaAndFather {
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        
        .simbaAndFather {
            display: flex;
            position: absolute;
            max-height: 100vh;
            width: 100vw;
            bottom: 0;
            z-index: 3;
            
            .image { 
                width: 350px;
                
                @media ${media.sm} {
                    max-width: 750px;
                    width: 60%;
                }
            }
        }
    }
        
    .animation__bird {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 10;
        
        .image {
            max-width: 1094px;
        }
    }
    
    .animation__background--step2 {
        background-image: linear-gradient(transparent, #010012);
        position: absolute;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: 2;
        
        .image {
            width: 100vw;
            height: 100vh;
        }
    }
    
    .animation__simbaGrownUp {
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
        
        .simbaGrownUp {
            display: flex;
            position: absolute;
            width: 100vh;
            bottom: 0;
            z-index: 6;
            
            .image { 
                width: 250px;
                transform: scaleX(-1);
                
                @media ${media.sm} {
                    transform: none;
                    max-width: 576px;
                    width: 50%;
                }
            }
        }
    }
`;

class Simba extends Component {
    render() {
        return (
            <Wrapper active={this.props.active} id="simbaWrapper">
                <div id="simbaScene"/>
                <Controller>
                    <Scene
                        triggerElement="#simbaScene"
                        triggerHook="onLeave"
                        duration={3000}
                        pin
                    >
                        {(progress) => (
                            <div className={`sticky`}>
                                <Absolute>
                                    <TitleBar absolute color="yellow">
                                        <h2>Simba</h2>
                                    </TitleBar>

                                    <PlayButton src="https://cdnapisec.kaltura.com/p/1068292/sp/106829200/embedIframeJs/uiconf_id/40058201/partner_id/1068292?iframeembed=true&playerId=kaltura_player&entry_id=1_anzhshgo&flashvars[localizationCode]=en&amp;flashvars[Kaltura.addCrossoriginToIframe]=true&amp;&wid=1_lm4awh3b"
                                                progress={progress} copy="Meet our young Simba and young Nala actors"/>

                                    <Timeline totalProgress={progress} paused>
                                        <div className="animation__background"/>

                                        <Tween
                                            from={{
                                                y: '100%', delay: 0
                                            }}
                                            duration={5}
                                            ease="Strong.easeOut"
                                            totalProgress={progress * 2.5}
                                            paused
                                        >
                                            <div className="animation__simbaAndFather slideInUp">
                                                <Relative>
                                                    <div className="simbaAndFather">
                                                        <GatsbyImage image={this.props.data.simbaAndFather.childImageSharp.gatsbyImageData}
                                                            alt=""
                                                            title=""
                                                            className="image"
                                                        />
                                                    </div>
                                                </Relative>
                                            </div>
                                        </Tween>

                                        <Tween
                                            from={{
                                                x: '100%',
                                                top: '-20%',
                                            }}
                                            to={{x: '-100%', top: '10%'}}
                                            duration={5}
                                        >
                                            <div className="animation__bird">
                                                <GatsbyImage image={this.props.data.birds.childImageSharp.gatsbyImageData}
                                                    alt=""
                                                    title=""
                                                    className="image"
                                                />
                                            </div>
                                        </Tween>

                                        <Tween
                                            to={{opacity: 0}}
                                            duration={3}
                                        >
                                            <CopyBox copyVerticalPositionSm="toTop" copyVerticalPositionMd="toTop" copyHorizontalPosition="toRight" step1>
                                                <p>
                                                    Simba is a young lion prince who is full of energy. He hugely
                                                    admires
                                                    his
                                                    father
                                                    Mufasa, the king.
                                                    Mufasa’s sudden death shocks Simba, leaving him unsure of his own
                                                    identity.
                                                </p>
                                            </CopyBox>
                                        </Tween>

                                        <Tween
                                            from={{opacity: 0, delay: "+4"}}
                                            to={{opacity: 1}}
                                            duration={5}
                                        >
                                            <div className="animation__background--step2">
                                                <GatsbyImage image={this.props.data.bgStep2Sm.childImageSharp.gatsbyImageData}
                                                    alt=""
                                                    title=""
                                                    className="image d-block d-sm-none"
                                                />
                                                <GatsbyImage image={this.props.data.bgStep2Md.childImageSharp.gatsbyImageData}
                                                    alt=""
                                                    title=""
                                                    className="image d-none d-sm-block d-lg-none"
                                                />
                                                <GatsbyImage image={this.props.data.bgStep2Lg.childImageSharp.gatsbyImageData}
                                                    alt=""
                                                    title=""
                                                    className="image d-none d-lg-block d-xl-none"
                                                />
                                                <GatsbyImage image={this.props.data.bgStep2.childImageSharp.gatsbyImageData}
                                                    alt=""
                                                    title=""
                                                    className="image d-none d-xl-block"
                                                />
                                            </div>
                                        </Tween>

                                        <Tween
                                            from={{y: '100%'}}
                                            duration={3}
                                        >
                                            <div className="animation__simbaGrownUp slideInUp">
                                                <Relative>
                                                    <div className="simbaGrownUp">
                                                        <GatsbyImage image={this.props.data.simbaGrownUp.childImageSharp.gatsbyImageData}
                                                            alt=""
                                                            title=""
                                                            className="image"
                                                        />
                                                    </div>
                                                </Relative>
                                            </div>
                                        </Tween>
                                        <Tween
                                            to={{opacity: 1}}
                                            duration={3}
                                        >
                                            <CopyBox copyVerticalPositionSm="toTop" copyVerticalPositionMd="toTop" copyHorizontalPosition="toRight" step2>
                                                <p>
                                                    He runs away, only to eventually return as a young adult lion to
                                                    take
                                                    his
                                                    place
                                                    as King of the Pridelands.
                                                </p>
                                            </CopyBox>
                                        </Tween>
                                    </Timeline>
                                    <ButtonScroll playBtn progress={progress}/>
                                </Absolute>
                            </div>
                        )}
                    </Scene>
                </Controller>
            </Wrapper>
        )
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                birds: file(relativePath: { eq: "Interactive/Characters/lke-im-char-simba-1-birds-1920.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1090, quality: 100, placeholder: NONE, formats: [AUTO, WEBP, AVIF])
                    }
                }
                
                simbaAndFather: file(relativePath: { eq: "Interactive/Characters/lke-im-char-simba-1-pic-1920.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 750, quality: 100, placeholder: NONE, formats: [AUTO, WEBP, AVIF])
                    }
                }
                
                bgStep2: file(relativePath: { eq: "Interactive/Characters/lke-im-char-simba-2-bg-1920.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                bgStep2Lg: file(relativePath: { eq: "Interactive/Characters/lke-im-char-simba-2-bg-1440.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1440, quality: 100)
                    }
                }
                
                bgStep2Md: file(relativePath: { eq: "Interactive/Characters/lke-im-char-simba-2-bg-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                bgStep2Sm: file(relativePath: { eq: "Interactive/Characters/lke-im-char-simba-2-bg-576.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
                
                simbaGrownUp: file(relativePath: { eq: "Interactive/Characters/lke-im-char-simba-2-pic-1920.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 576, quality: 100, placeholder: NONE, formats: [AUTO, WEBP, AVIF])
                    }
                }
            }
		`}
        render={data => (
            <Simba data={data} active={props.active} title={props.title}/>
        )}
    />
)
