// @flow
import React, {Component} from 'react';
import styled, {css} from 'styled-components';
import {media} from "utils/Media"
import {Controller, Scene} from 'react-scrollmagic';
import {Tween, Timeline} from 'react-gsap';
import {graphql, StaticQuery} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import TitleBar from 'components/shared/TitleBar'
import CopyBox from "components/shared/CopyBox"
import ButtonScroll from "components/shared/ScrollButton"
import Absolute from "components/shared/Absolute"

const Wrapper = styled.div`
  overflow: hidden;
  
  .scrollmagic-pin-spacer {
    display: none !important;

    ${props => props.active && css`
        display: block !important;
    `}

    @media ${media.sm} {
        display: block !important;
    }
  }
  
  .sticky, .sticky2 { 
    height: 0;
    overflow: hidden;
    width: 100% !important;
    
    ${props => props.active && css`
        height: 100vh;
    `}
    
    @media ${media.sm} {
        height: 100vh;
    }
  }
  
  .fs {
        height: 100vh;
    }
    
     .slideInUp {
        .no-touch & {
            visibility: hidden;
            opacity: 0;
            transition: all 0.7s ease-out;
            transform: translate3d(0, 50px, 0);
        }
        .no-touch .is-active & {
            visibility: visible;
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
    
    .pinContainer {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
    }
    
    .animation__background {
        width: 100vw;
        height: 100vh;
        position: absolute;
        left: 0;
        z-index: 2;
        top: 0;
        
        &--overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 3;
            background-image: linear-gradient(#000, transparent);
        }
        
        .image {
            width: 100vw;
            height: 100vh;
        }
    }
        
    .animation__bug {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 9;
        
        .image {
            max-width: 355px;
        }
    }
`;

class TimonAndPumbaa extends Component {
    render() {
        return (
            <Wrapper active={this.props.active} id="timonAndPumbaaWrapper">
                <div id="timonAndPumbaaScene"/>
                <Controller>
                    <Scene
                        triggerElement="#timonAndPumbaaScene"
                        triggerHook="onLeave"
                        duration={2000}
                        pin
                    >
                        {(progress) => (
                            <div className={`sticky2`}>
                                <Absolute>
                                <Timeline totalProgress={progress} paused>
                                    <Tween
                                        ease="Strong.easeOut"
                                        totalProgress={progress * 2}
                                        paused
                                    >
                                        <TitleBar absolute color="green">
                                            <h2>Timon & Pumbaa</h2>
                                        </TitleBar>
                                    </Tween>

                                    <Tween
                                        to={{
                                            scale: 1.1
                                        }}
                                        ease="Strong.easeOut"
                                        totalProgress={progress}
                                        paused
                                    >
                                        <div className="animation__background">
                                            <Tween
                                                to={{
                                                    backgroundImage: "linear-gradient(transparent, transparent)"
                                                }}
                                                ease="Strong.easeOut"
                                                totalProgress={progress}
                                                paused
                                            >
                                                <div className="animation__background--overlay"/>
                                            </Tween>
                                            <GatsbyImage image={this.props.data.bgStep1Sm.childImageSharp.gatsbyImageData}
                                                alt=""
                                                title=""
                                                className="image d-block d-sm-none"
                                            />
                                            <GatsbyImage image={this.props.data.bgStep1Md.childImageSharp.gatsbyImageData}
                                                alt=""
                                                title=""
                                                className="image d-none d-sm-block d-lg-none"
                                            />
                                            <GatsbyImage image={this.props.data.bgStep1Lg.childImageSharp.gatsbyImageData}
                                                alt=""
                                                title=""
                                                className="image d-none d-lg-block d-xl-none"
                                            />
                                            <GatsbyImage image={this.props.data.bgStep1.childImageSharp.gatsbyImageData}
                                                alt=""
                                                title=""
                                                className="image d-none d-xl-block"
                                            />
                                        </div>
                                    </Tween>

                                    <Tween
                                        from={{backgroundImage: "linear-gradient(#fff, #fff)", opacity: 1}}
                                        to={{
                                            backgroundImage: "linear-gradient(#fff, transparent)",
                                            opacity: 0,
                                            delay: 5
                                        }}
                                        duration={2}
                                    >
                                        <CopyBox copyVerticalPositionMd="toTop" copyHorizontalPosition="toLeft" step1>
                                            <p>Timon, a street-wise meerkat and Pumbaa, a warm-hearted warthog, are
                                                best friends with a problem-free philosophy. </p>
                                            <p>They take Simba under their wing when he runs away from home and turn
                                                out to be loyal, dedicated, funny companions!</p>
                                        </CopyBox>
                                    </Tween>

                                    <Tween
                                        from={{
                                            x: '0',
                                            top: '-340px',
                                        }}
                                        to={{x: '10%', top: '100%'}}
                                        duration={15}
                                    >
                                        <div className="animation__bug">
                                            <GatsbyImage image={this.props.data.bugs.childImageSharp.gatsbyImageData}
                                                alt=""
                                                title=""
                                                className="image"
                                            />
                                        </div>
                                    </Tween>

                                    <Tween
                                        to={{opacity: 1}}
                                        duration={3}
                                    >
                                        <CopyBox copyVerticalPositionMd="toTop" copyHorizontalPosition="toLeft" step2 quote color="green">
                                            <div>
                                                <p>
                                                    It means no worries for the rest of your days!
                                                </p>
                                            </div>
                                        </CopyBox>
                                    </Tween>
                                </Timeline>
                                <ButtonScroll progress={progress}/>
                                </Absolute>
                            </div>
                        )}
                    </Scene>
                </Controller>
            </Wrapper>
        )
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                bugs: file(relativePath: { eq: "Interactive/Characters/lke-im-char-timon-bugs-1920.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 355, quality: 100, placeholder: NONE, formats: [AUTO, WEBP, AVIF])
                    }
                }
                
                bgStep1: file(relativePath: { eq: "Interactive/Characters/lke-im-char-timon-bg-1920.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                bgStep1Lg: file(relativePath: { eq: "Interactive/Characters/lke-im-char-timon-bg-1440.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1440, quality: 100)
                    }
                }
                
                bgStep1Md: file(relativePath: { eq: "Interactive/Characters/lke-im-char-timon-bg-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                bgStep1Sm: file(relativePath: { eq: "Interactive/Characters/lke-im-char-timon-bg-576.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
            }
		`}
        render={data => (
            <TimonAndPumbaa data={data} active={props.active} title={props.title}/>
        )}
    />
)
