// @flow
import React, {Component} from 'react';
import styled, {css} from 'styled-components';
import {Controller, Scene} from 'react-scrollmagic';
import {graphql, StaticQuery} from "gatsby";
import {media} from "utils/Media"
import SwipeScene from "components/shared/SwipeScene"
import ButtonSwipe from "components/shared/ButtonSwipe"
import CopyBox from "components/shared/CopyBox"
import TitleBar from 'components/shared/TitleBar'
import BgImage from 'components/shared/BgImage'

const Relative = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const Wrapper = styled.div`
    overflow: hidden;
    position: relative;

 .scrollmagic-pin-spacer {
    display: none !important;

    ${props => props.active && css`
        display: block !important;
    `}

    @media ${media.sm} {
        display: block !important;
    }
  }
  
  .sticky, .sticky2 { 
    height: 0;
    overflow: hidden;
    width: 100% !important;
    
    ${props => props.active && css`
        height: 100vh;
    `}
    
    @media ${media.sm} {
        height: 100vh;
    }
  }
  
  .fs {
        height: 100vh;
    }
    
  .slideInUp {
        .no-touch & {
            visibility: hidden;
            opacity: 0;
            transition: all 0.7s ease-out;
            transform: translate3d(0, 50px, 0);
        }
        .no-touch .is-active & {
            visibility: visible;
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
    
    .pinContainer {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
    }
    
`;

class Mufasa extends Component {
    render() {
        return (
            <Wrapper active={this.props.active} id="mufasaWrapper">
                <div id="mufasaScene" ref={mufasaScene => this.mufasaScene = mufasaScene}/>
                <Controller>
                    <Scene
                        triggerElement="#mufasaScene"
                        triggerHook="onLeave"
                        duration={500}
                        pin
                    >
                        {(progress) => (
                            <div className={`sticky2`}>
                                <TitleBar color="orange">
                                    <h2>Mufasa</h2>
                                </TitleBar>

                                <BgImage
                                    data={this.props.data}
                                    play={progress > 0}
                                    progress={progress}
                                />

                                <ButtonSwipe
                                    target={this.mufasaSwipe}
                                    play={progress > 0}
                                />
                                <Relative>
                                    <CopyBox copyVerticalPositionSm="toTop" copyVerticalPositionMd="toTop" copyHorizontalPosition="toRight" step1>
                                        <p>
                                            Mufasa is a strong, brave and wise king and an understanding father.
                                        </p>
                                        <p>
                                            He teaches Simba about being a responsible leader, and the importance of the
                                            great
                                            circle of life.
                                        </p>
                                    </CopyBox>

                                    <SwipeScene
                                        ref={mufasaSwipe => this.mufasaSwipe = mufasaSwipe}
                                        bgImageSm={this.props.data.bgStep2Sm.childImageSharp.gatsbyImageData}
                    bgImageMd={this.props.data.bgStep2Md.childImageSharp.gatsbyImageData}
                    bgImageLg={this.props.data.bgStep2Lg.childImageSharp.gatsbyImageData}
                    bgImageXl={this.props.data.bgStep2.childImageSharp.gatsbyImageData}
                                        text="<p>Simba, everything exists in a delicate balance. As King, you need to understand that balance and respect all the creatures – from the crawling ant to the leaping antelope.</p>"
                                        quote={true}
                                        color="orange"
                                        copyVerticalPosition="toTop"
                                        copyVerticalPositionSm="toTop"
                                        copyVerticalPositionMd="toTop"
                                    />
                                </Relative>
                            </div>
                        )}
                    </Scene>
                </Controller>
            </Wrapper>
        )
    }
}


export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                 bgStep1: file(relativePath: { eq: "Interactive/Characters/lke-im-char-mufasa-1-bg-1920.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                bgStep1Lg: file(relativePath: { eq: "Interactive/Characters/lke-im-char-mufasa-1-bg-1440.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1440, quality: 100)
                    }
                }
                
                bgStep1Md: file(relativePath: { eq: "Interactive/Characters/lke-im-char-mufasa-1-bg-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                bgStep1Sm: file(relativePath: { eq: "Interactive/Characters/lke-im-char-mufasa-1-bg-576.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
                
                bgStep2: file(relativePath: { eq: "Interactive/Characters/lke-im-char-mufasa-2-bg-1920.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                bgStep2Lg: file(relativePath: { eq: "Interactive/Characters/lke-im-char-mufasa-2-bg-1440.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1440, quality: 100)
                    }
                }
                
                bgStep2Md: file(relativePath: { eq: "Interactive/Characters/lke-im-char-mufasa-2-bg-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                bgStep2Sm: file(relativePath: { eq: "Interactive/Characters/lke-im-char-mufasa-2-bg-576.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
            }
		`}
        render={data => (
            <Mufasa data={data} active={props.active} title={props.title}/>
        )}
    />
)
