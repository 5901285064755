import React, {Component} from "react"
import styled, { css } from 'styled-components';
import { media } from "utils/Media"
import {GatsbyImage} from "gatsby-plugin-image"
import CopyBox from "components/shared/CopyBox"
import LazyLoad from 'react-lazyload';

const SwipeWrap = styled.div`
    width: 100vw;
    position: absolute;
    z-index: 8;
    left: 0;
    transform: translateX(-100%);
    transition: all .8s ease-in-out;
    background-color: black;
    
    opacity: 0;
    height: 100vh; 
    top: 0;

    &.active {
        transform: translateX(0);
        opacity: 1;
    }
    
    &.notFullWidth {
       background: linear-gradient(0deg, #000 38%, rgba(0, 0, 0, 0) 100%);
       //background: none;
        left: auto;
        transform: none;
        right: 100vw;
        width: 100%;
        
        @media ${media.md} {
          width: 346px;
        }
        
        @media ${media.xl} {
          min-width: 580px;
        }
        
        &.active {
          right: 0;
        }
    }
`

const BgWrapper = styled.div`
     width: 100vw;
        height: 100vh;
        position: absolute;
        left: 0;
        z-index: 2;
        top: 0;
        
        &--step2 {
            opacity: 0;
        }
        
        .image {
            width: 100vw;
            height: 100vh;
        }
`
const BgImg = styled(GatsbyImage)`
    pointer-events: none;
`

class SwipeScene extends Component {

    render() {
        return (
            <SwipeWrap className={`${this.props.notFullWidth ? 'notFullWidth' : ''}`}>
                {this.props.changeBg &&
                    <BgWrapper>
                        
                        <LazyLoad height={"100%"} once>
                        <BgImg image={this.props.bgImageSm}
                            alt=""
                            title=""
                            className="image d-block d-sm-none"
                        />
                        </LazyLoad>
                        <LazyLoad height={"100%"} once>
                        <BgImg image={this.props.bgImageMd}
                            alt=""
                            title=""
                            className="image d-none d-sm-block d-lg-none"
                        />
                        </LazyLoad>
                        <LazyLoad height={"100%"} once>
                        <BgImg image={this.props.bgImageLg}
                            alt=""
                            title=""
                            className="image d-none d-lg-block d-xl-none"
                        />
                        </LazyLoad>
                        <LazyLoad height={"100%"} once>
                        <BgImg image={this.props.bgImageXl}
                            alt=""
                            title=""
                            className="image d-none d-xl-block"
                        />
                        </LazyLoad>
                    </BgWrapper>
                }
                <CopyBox
                    copyVerticalPosition={this.props.copyVerticalPosition}
                    copyHorizontalPosition={this.props.copyHorizontalPosition}
                    copyHorizontalPositionMd={this.props.copyHorizontalPositionMd}
                    copyVerticalPositionMd={this.props.copyVerticalPositionMd}
                    copyVerticalPositionXs={this.props.copyVerticalPositionXs}
                    copyMdWidth={this.props.copyMdWidth}
                    quote={this.props.quote}
                    color="orange">
                    <div dangerouslySetInnerHTML={{__html: this.props.text}}/>
                </CopyBox>
            </SwipeWrap>
        )
    }
}

SwipeScene.defaultProps = {
    textPosition: "left",
    quote: false,
    changeBg: true,
    notFullWidth: false,
    transparentBg: false,
    copyHorizontalPosition: "toLeft",
    copyVerticalPosition: "toMiddle",
    copyVerticalPositionXs: "toTop"
}

export default SwipeScene