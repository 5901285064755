// @flow
import React, {Component} from 'react';
import styled, {css} from 'styled-components';
import {media} from "utils/Media"
import {Controller, Scene} from 'react-scrollmagic';
import {graphql, StaticQuery} from "gatsby";
import PlayButton from 'components/shared/PlayButton'
import TitleBar from 'components/shared/TitleBar'
import CopyBox from "components/shared/CopyBox"
import BgImage from 'components/shared/BgImage'
import Absolute from "components/shared/Absolute"

const Wrapper = styled.div`
    overflow: hidden;
    
  .scrollmagic-pin-spacer {
    display: none !important;

    ${props => props.active && css`
        display: block !important;
    `}

    @media ${media.sm} {
        display: block !important;
    }
  }
  
  .sticky, .sticky2 { 
    height: 0;
    overflow: hidden;
    width: 100% !important;
    
    ${props => props.active && css`
        height: 100vh;
    `}
    
    @media ${media.sm} {
        height: 100vh;
    }
  }
  
  .fs {
        height: 100vh;
    }
    
  .slideInUp {
        .no-touch & {
            visibility: hidden;
            opacity: 0;
            transition: all 0.7s ease-out;
            transform: translate3d(0, 50px, 0);
        }
        .no-touch .is-active & {
            visibility: visible;
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
    
    .pinContainer {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
    }  
`;

class Zazu extends Component {
    render() {
        return (
            <Wrapper active={this.props.active} id="zazuWrapper">
                <div id="zazuScene"/>
                <Controller>
                    <Scene
                        triggerElement="#zazuScene"
                        triggerHook="onLeave"
                        duration={500}
                        pin
                    >
                        {(progress) => (
                            <div className={`sticky2`}>
                                <Absolute>
                                <PlayButton src="https://cdnapisec.kaltura.com/p/1068292/sp/106829200/embedIframeJs/uiconf_id/40058201/partner_id/1068292?iframeembed=true&playerId=kaltura_player&entry_id=1_qsj974mb&flashvars[localizationCode]=en&amp;flashvars[Kaltura.addCrossoriginToIframe]=true&amp;&wid=1_ioh92y7x"
                                    progress={progress} copy="Learn more about the masks and puppets"/>

                                <TitleBar absolute color="blue" textWhite>
                                    <h2>Zazu</h2>
                                </TitleBar>

                                <BgImage
                                    data={this.props.data}
                                    play={progress > 0}
                                    progress={progress}
                                    noTitleBar
                                />

                                <CopyBox copyVerticalPositionMd="toTop" copyVerticalPositionSm="toTop" copyHorizontalPosition="toLeft" step1>
                                    <p>
                                        Zazu is a hornbill and Mufasa’s most trusted advisor. He is extremely loyal to
                                        the
                                        king and takes his duties seriously. Zazu likes to stick to the rules and keep
                                        things orderly.
                                    </p>
                                </CopyBox>
                                </Absolute>
                            </div>
                        )}
                    </Scene>
                </Controller>
            </Wrapper>
        )
    }
}


export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                bgStep1: file(relativePath: { eq: "Interactive/Characters/lke-im-char-zazu-bg-1920.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                bgStep1Lg: file(relativePath: { eq: "Interactive/Characters/lke-im-char-zazu-bg-1440.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1440, quality: 100)
                    }
                }
                
                bgStep1Md: file(relativePath: { eq: "Interactive/Characters/lke-im-char-zazu-bg-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                bgStep1Sm: file(relativePath: { eq: "Interactive/Characters/lke-im-char-zazu-bg-576.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
            }
		`}
        render={data => (
            <Zazu data={data} active={props.active} title={props.title}/>
        )}
    />
)
