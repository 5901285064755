// @flow
import React, {Component} from 'react';
import styled, {css} from 'styled-components';
import {media} from "utils/Media"
import {Controller, Scene} from 'react-scrollmagic';
import {Tween, Timeline} from 'react-gsap';
import {graphql, StaticQuery} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import PlayButton from 'components/shared/PlayButton'
import TitleBar from 'components/shared/TitleBar'
import CopyBox from "components/shared/CopyBox"
import ButtonScroll from "components/shared/ScrollButton"
import Absolute from "components/shared/Absolute"

const Relative = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const Wrapper = styled.div`
  overflow: hidden;
  
  .scrollmagic-pin-spacer {
    display: none !important;

    ${props => props.active && css`
        display: block !important;
    `}

    @media ${media.sm} {
        display: block !important;
    }
  }
  
  .sticky, .sticky2 { 
    height: 0;
    overflow: hidden;
    width: 100% !important;
    
    ${props => props.active && css`
        height: 100vh;
    `}
    
    @media ${media.sm} {
        height: 100vh;
    }
  }
  
  .fs {
        height: 100vh;
    }
    
     .slideInUp {
        .no-touch & {
            visibility: hidden;
            opacity: 0;
            transition: all 0.7s ease-out;
            transform: translate3d(0, 50px, 0);
        }
        .no-touch .is-active & {
            visibility: visible;
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
    
    .pinContainer {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
    }
    
    .animation__background {
        position: absolute;
        width: 100vw;
        height: 100vh;
        z-index: 1;
        background: linear-gradient(#6480BF, #000);
    }
    
    
    .animation__scarStanding {
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        
        .scarStanding {
            display: flex;
            position: absolute;
            width: 100vw;
            bottom: 0;
            z-index: 6;
            
            .image { 
                width: 50vh;
                
                @media ${media.md} {
                    max-width: 750px;
                    width: 60%;
                }
            }
        }
    }
    
    .animation__background--step2 {
        background-image: linear-gradient(transparent, #010012);
        position: absolute;
        width: 100vw;
        height: 100vh;
        opacity: 0;
        z-index: 4;
        
        .image {
            width: 100vw;
            height: 100vh;
        }
    }  
`;

class Scar extends Component {
    render() {
        return (
            <Wrapper active={this.props.active} id="scarWrapper">
                <div id="scarScene"/>
                <Controller>
                    <Scene
                        triggerElement="#scarScene"
                        triggerHook="onLeave"
                        duration={2000}
                        pin
                    >
                        {(progress) => (
                            <div className={`sticky2`}>
                                <Absolute>
                                <PlayButton
                                    src="https://cdnapisec.kaltura.com/p/1068292/sp/106829200/embedIframeJs/uiconf_id/40058201/partner_id/1068292?iframeembed=true&playerId=kaltura_player&entry_id=1_l2pbzxpb&flashvars[localizationCode]=en&flashvars[Kaltura.addCrossoriginToIframe]=true&&wid=1_0up7tyy1"
                                            progress={progress} copy="An actor prepares"/>

                                <Timeline totalProgress={progress} paused>
                                    <Tween
                                        ease="Strong.easeOut"
                                        totalProgress={progress * 2}
                                        paused
                                    >
                                        <TitleBar absolute color="darkGrey" textWhite>
                                            <h2>Scar</h2>
                                        </TitleBar>
                                    </Tween>

                                    <div className="animation__background"/>

                                    <Tween
                                        from={{
                                            y: '100%', delay: 0
                                        }}
                                        duration={7}
                                        ease="Strong.easeOut"
                                        totalProgress={progress}
                                        paused
                                    >
                                        <div className="animation__scarStanding slideInUp">
                                            <Relative>
                                                <div className="scarStanding">
                                                    <GatsbyImage image={this.props.data.scarStanding.childImageSharp.gatsbyImageData}
                                                        alt=""
                                                        title=""
                                                        className="image"
                                                    />
                                                </div>
                                            </Relative>
                                        </div>
                                    </Tween>
                                    <Tween
                                        to={{opacity: 0}}
                                        duration={2}
                                    >
                                        <CopyBox copyVerticalPositionMd="toTop" copyHorizontalPosition="toRight" step1>
                                            <p>
                                                Scar is the younger brother of Mufasa and Simba’s uncle. He is bitter
                                                and
                                                untrustworthy, believing that he should be King.
                                            </p>
                                        </CopyBox>
                                    </Tween>

                                    <Tween
                                        from={{opacity: 0, delay: 10}}
                                        to={{opacity: 1}}
                                        duration={3}
                                    >
                                        <div className="animation__background--step2">
                                            <GatsbyImage image={this.props.data.bgStep2Sm.childImageSharp.gatsbyImageData}
                                                alt=""
                                                title=""
                                                className="image d-block d-sm-none"
                                            />
                                            <GatsbyImage image={this.props.data.bgStep2Md.childImageSharp.gatsbyImageData}
                                                alt=""
                                                title=""
                                                className="image d-none d-sm-block d-lg-none"
                                            />
                                            <GatsbyImage image={this.props.data.bgStep2Lg.childImageSharp.gatsbyImageData}
                                                alt=""
                                                title=""
                                                className="image d-none d-lg-block d-xl-none"
                                            />
                                            <GatsbyImage image={this.props.data.bgStep2.childImageSharp.gatsbyImageData}
                                                alt=""
                                                title=""
                                                className="image d-none d-xl-block"
                                            />
                                        </div>
                                    </Tween>
                                    <Tween
                                        to={{opacity: 1, delay: 0}}
                                        duration={2}
                                    >
                                        <CopyBox copyVerticalPositionMd="toTop" copyHorizontalPosition="toRight" step2>
                                            <p>
                                                Scar ruthlessly kills Mufasa and proves to be a weak and selfish
                                                leader.
                                            </p>
                                        </CopyBox>
                                    </Tween>
                                </Timeline>
                                <ButtonScroll playBtn progress={progress}/>
                                </Absolute>
                            </div>
                        )}
                    </Scene>
                </Controller>
            </Wrapper>
        )
    }
}


export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                scarStanding: file(relativePath: { eq: "Interactive/Characters/lke-im-char-scar-1-pic-1920.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 750, quality: 100, placeholder: NONE, formats: [AUTO, WEBP, AVIF])
                    }
                }
            
                bgStep2: file(relativePath: { eq: "Interactive/Characters/lke-im-char-scar-2-bg-1920.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                bgStep2Lg: file(relativePath: { eq: "Interactive/Characters/lke-im-char-scar-2-bg-1440.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1440, quality: 100)
                    }
                }
                
                bgStep2Md: file(relativePath: { eq: "Interactive/Characters/lke-im-char-scar-2-bg-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                bgStep2Sm: file(relativePath: { eq: "Interactive/Characters/lke-im-char-scar-2-bg-576.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
         
            }
		`}
        render={data => (
            <Scar data={data} active={props.active} title={props.title}/>
        )}
    />
)
